

import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface CreatedPosts {
    posts:{
        [index: number]:{
            _id: string; 
            dueDate: number; 
            notifications: Array<string>;
            tags: Array<string>;
            title: string;
            details: string;
        }
    }
}


@Module
export default class PostModule extends VuexModule implements CreatedPosts {
    posts = [];

    @Mutation
    [Mutations.SET_POSTS](payload): void {
      this.posts = payload;
    }

    @Action
    [Actions.ADD_POSTS](payload) {
        this.context.commit(Mutations.SET_POSTS, payload);
    }
    @Action
    [Actions.GET_UPDATES]() {
        return new Promise<void>((resolve, reject) => {
            ApiService.get("getUpdates")
            .then(({ data }) => {
                this.context.dispatch(Actions.ADD_POSTS, data || []);
                resolve();
            })
            .catch(( response ) => {
                console.log(response)
                this.context.commit(Mutations.SET_ERROR, [response]);
                reject();
            });
        });
    }
    @Action
    [Actions.POST_UPDATES](data) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("update", { data })
            .then(() => {
                resolve();
            })
            .catch((response) => {
                console.log(response)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
                reject();
            });
        });
    }

    @Action
    [Actions.DELETE_POST](id) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("deletePost", { data: id } )
            .then(() => {
                resolve();
            })
            .catch(({ response }) => {
                console.log(response)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
                reject();
            });
        });
    }
    
    get GET_POSTS(): any {
        return this.posts;
    }
}

