
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      // (async function() {

      //     let ws = await connectToServer().then( r => { loop(); return r } ).catch(e=> console.log(e));    

      //     let btn = document.getElementById('send');
      //     btn.addEventListener('click', e => {
      //         let text = document.getElementById('sender').value;
      //         let d = {type:'message', value: text, mask:true};
      //         console.log(d);
      //         // ws.send(d);
      //         ws.send(JSON.stringify(d));
      //     });

      //     // console.log(ws);
      //     function loop(){
      //         return;
      //         setInterval(()=>{
      //             console.log('loop')
      //             ws.send({type:'senchiu'})
      //         }, 500)
      //     }
      //     // ws.send({type:'senchiu'})
      //     async function connectToServer() {    
      //         let wss = new WebSocket('ws://localhost:8080');
              
      //         wss.onerror = e => {
      //             console.error(e);
      //         };
      //         wss.onclose = async e => {
      //             console.log('socket lost connection');
      //             ws = await connectToServer().catch(e=>{
      //                 console.log(e)
      //             });
      //             console.log('socket reconnected');
      //         };
      //         wss.onmessage = event => {
                  
      //             if (event.data instanceof Blob) {
      //                 return console.log(event.data);
      //                 var buf = new Uint8Array(event.data).buffer;
      //                 var dv = new DataView(buf);
      //                 return console.log(dv);
      //                 var reader = new FileReader();
                      
      //                 reader.onload = function () {
      //                     console.log('Blob', reader.result);
      //                 }
      //                 reader.readAsText(event.data);
      //             } else {
      //                 console.log("Result: ", event.data);
      //             }
      //         }; 
      //         return new Promise((resolve, reject) => {
      //             const timer = setInterval(() => {
      //                 if(wss.readyState === 1) {
      //                     console.log('socket connected');
      //                     // wss.binaryType = 'arraybuffer';
      //                     clearInterval(timer);
      //                     resolve(wss);
      //                 }
      //             }, 1000);
      //         });   
      //     }
      // })();

    });

    
  },
});
